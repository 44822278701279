<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input
            placeholder="会员昵称"
            class="search-input"
            clearable
            v-model="searchObj.nickname"
          ></el-input>
          <el-input
            placeholder="手机号码"
            class="search-input ml-10"
            clearable
            v-model="searchObj.phone"
          ></el-input>
          <el-input
            placeholder="累计签到天数"
            class="search-input ml-10"
            clearable
            v-model="searchObj.countSignIn"
          >
            <template slot="append">天</template>
          </el-input>
          <el-date-picker
            class="ml-10"
            v-model="date"
            value-format="yyyy-MM-dd"
            type="daterange"
            clearable
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="ml-10"
            @click="search"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" class="ml-10" @click="reset"
            >重置</el-button
          >
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw">
            <div class="flx-row ali-c">
              <router-link :to="{ name: 'Checkin_setting' }">
                <el-button class="ml-15" type="primary">签到设置</el-button>
              </router-link>
            </div>
          </div>
          <!-- 表格 -->
          <el-table
            class="mt-24 member-table"
            :data="tableData"
            @selection-change="tableSelect"
          >
            <el-table-column label="会员昵称" prop="nickname"></el-table-column>
            <el-table-column label="手机号码" prop="phone"></el-table-column>
            <el-table-column
              label="首次签到时间"
              prop="firstSignInTime"
            ></el-table-column>
            <el-table-column
              label="上次签到时间"
              prop="lastSignInTime"
            ></el-table-column>
            <el-table-column
              label="累计签到天数"
              prop="countSignIn"
            ></el-table-column>
            <el-table-column label="最长连续签到天数" prop="continuousSignIn">
              <template slot-scope="scope">
                {{
                  scope.row.continuousSignIn ? scope.row.continuousSignIn : "--"
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="获得积分"
              prop="rewordPoints"
            ></el-table-column>
            <el-table-column
              label="获得奖品数量"
              prop="rewordCount"
            ></el-table-column>
          </el-table>
          <Pagination
            :total="total"
            :pageNum="pageNum"
            :pageSize="pageSize"
            :pageSizes="pageSizes"
            @changePage="search"
            @syncPageData="syncPageData"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { getlrSignInRulesList } from "@/api/maketing/checkin";
export default {
  name: "checkin_list",
  components: {
    Pagination,
  },
  data() {
    return {
      // 顶部搜索栏数据
      searchObj: {
        nickname: "",
        phone: "",
        countSignIn: "",
      },
      date: [],
      // 优惠券表格数据内容
      tableData: [],
      // 会员-当前分页与总条数
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
    };
  },
  created() {
    this.initData();
  },
  methods: {
    // 搜索事件
    search() {
      let data = {
        nickname: this.searchObj.nickname,
        phone: this.searchObj.phone,
        countSignIn: this.searchObj.countSignIn,
        beginDate: this.date[0],
        endDate: this.date[1],
      };
      this.initData(data);
    },
    //初始化数据
    initData(searchData = {}) {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      data = Object.assign(data, searchData);
      getlrSignInRulesList(data).then((res) => {
        this.tableData = res.data.body.list;
        this.total = res.data.body.total;
      });
    },
    // 重置搜索关键词
    reset() {
      (this.searchObj = {
        nickname: "",
        phone: "",
        countSignIn: "",
      }),
        (this.date = []);
      this.initData();
    },
    // 详情
    toDtails(e) {
      // console.log(e);
    },
    // 会员多选事件
    tableSelect(e) {
      // // console.log(e);
      this.multiSeletction = e;
    },
    // 优惠券多选事件
    selectCou(e) {
      this.selectedCou = e;
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
  },
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
</style>
