import request from '@/utils/request'

// 签到设置编辑提交
export function lrSignInRulesEdit (data) {
    return request({
        url: "/customer-service/lrSignInRules/saveSignInRules",
        method:"post",
        data
    })
}

// 获取签到设置详情
export function getlrSignInRulesDetail () {
    return request({
        url:  "/customer-service/lrSignInRules/querySignInRules",
        method: 'get'
    })
}

// 获取签到设置列表
export function getlrSignInRulesList (data) {
    return request({
        url:  "/customer-service/lrSignIn/querySign",
        method: 'post',
        data
    })
}
